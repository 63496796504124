import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import howToUploadReceipt from "../../assets/svgs/uploadReceiptLogo.svg";
import bigHowToUploadReceipt from "../../assets/uploadReceiptLogoBig.png";

const CollectPoints = () => {
  const { t } = useTranslation();
  return (
    <div id="collect-points">
      <h2 className="dbc-title ms-4">{t("waysto")}</h2>

      <div className="collect-points-content">
        <Link
          className="collect-points-item mobile"
          to={{ pathname: "/scancode", isDesktop: true }}
          style={{ textDecoration: "none" }}
        >
          <div className="collect-points-img-container">
            <img
              src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/qr-code-scan.png"
              alt="tips"
              className="img-fluid"
            />
          </div>

          <div>
            <h5 className="m-0 p-0 fw-bold">{t("scanproductcode")}</h5>
          </div>
        </Link>
        <Link
          className="collect-points-item desktop"
          to={{ pathname: "/scancode", isDesktop: true }}
          style={{ textDecoration: "none" }}
        >
          <div className="collect-points-img-container">
            <img
              src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/qr-code-scan.png"
              alt="tips"
              className="img-fluid"
            />
          </div>

          <div>
            <h5 className="m-0 p-0 fw-bold">{t("scanproductcodeDesktop")}</h5>
          </div>
        </Link>
        <Link
          className="collect-points-item"
          to={{ pathname: "/uploadReceipt", isDesktop: true }}
          style={{ textDecoration: "none" }}
        >
          <div className="collect-points-img-container">
            <img
              src={bigHowToUploadReceipt}
              alt="tips"
              className="img-fluid"
              style={{ height: "88px", width: "auto", objectFit: "contain" }}
            />
          </div>

          <div>
            <h5 className="m-0 p-0 fw-bold">{t("howToUploadReceipt")}</h5>
          </div>
        </Link>
        <div className="collect-points-item">
          <div className="collect-points-img-container">
            <img
              src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-my-quest.png"
              alt="tips"
              className="img-fluid"
            />
          </div>

          <div>
            <h5 className="m-0 p-0 fw-bold">{t("doquests")}</h5>
          </div>
        </div>
        <Link
          to="/referandearn"
          className="collect-points-item"
          style={{ textDecoration: "none" }}
        >
          <div className="collect-points-img-container">
            <img
              src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-dbc-forum.png"
              alt="tips"
              className="img-fluid"
            />
          </div>

          <div>
            <h5 className="m-0 p-0 fw-bold">{t("referfriends")}</h5>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CollectPoints;
