import { useState } from "react";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";

const DropdownComponent = ({ title, dropdownInput }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <div className="product-table">
      <div
        variant="success"
        id={`dropdown-basic`}
        className="custom-dropdown-toogle"
        onClick={() => {
          setOpenDropdown(!openDropdown);
        }}
      >
        {title}
        {/* <DropdownButton
          variant="success"
          id={`dropdown-basic dropdown-button-drop-${
            openDropdown ? "up" : "down"
          }`}
          menuAlign="right"
          drop={openDropdown ? "up" : "down"}
          disabled
          className="custom-dropdown-button"
        ></DropdownButton> */}
        <div
          className={`${openDropdown ? "triangle-up" : "triangle-down"}`}
        ></div>
      </div>
      <ul className={`dropdown-item-menu ${openDropdown ? "open" : ""}`}>
        {dropdownInput.map((item, index) => (
          <li key={index} className="dropdown-item-menu-list">
            {" "}
            <div className="dropdown-item-menu-list-img-and-name">
              <img
                src={item.img}
                className="dropdown-item-menu-list-img"
                alt={`inputimage-${index}`}
              ></img>
              <p className="dropdown-item-menu-list-text">{item.name}</p>
            </div>
            <div className="size-and-points">
              <p className="dropdown-item-menu-list-text">{item.size}</p>
              <p className="dropdown-item-menu-list-text">{item.points}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownComponent;
