// import desktopHeader from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/desktopTopBG.png";
import topBM from "../../assets/topBm.jpg";
import topEN from "../../assets/topEn.jpg";
import { connect } from "react-redux";
// import mysteryBoxBanner from "../../assets/enLatestMysteryBoxBackground.png";
// import bmMysteryBoxBanner from "../../assets/bmLatestMysteryBoxBackground.png";

const ContentHeader = ({ title, language, newImageEn, newImageBm }) => {
  console.log(language);
  return (
    <>
      <div className="top-header">{title ? <h2>{title}</h2> : null}</div>
      {/* <div className="top-header">
				<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/fit-a-thon-font.png" alt="fit a thon title" style={{height:"52px"}} />
			</div> */}
      {/* <img
				src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/desktopTopBG.png"
				alt="head"
				className="img-fluid desktop-top-header"
			/> */}
      <img
        src={
          newImageEn && newImageBm
            ? language === "en"
              ? newImageEn
              : newImageBm
            : language === "en"
            ? topEN
            : topBM
        }
        alt="head"
        className="img-fluid desktop-top-header"
      />
    </>
  );
};

// export default ContentHeader;
const mapStateToProps = (state) => {
  return {
    language: state.pageReducer.language,
  };
};

export default connect(mapStateToProps, null)(ContentHeader);
