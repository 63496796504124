import React from "react";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../../../component/dropdown/DropdownComponent";
import dBabyBath from "../../../assets/drypersBabyBath.png";
import babyShampoo from "../../../assets/babyShampoo.png";
import babyPowder from "../../../assets/babyPowder.png";
import softLotionTissue from "../../../assets/softLotionTissue.png";
import headToToe1 from "../../../assets/headToToe1.png";
import headToToe2 from "../../../assets/headToToe2.png";
import headToToe3 from "../../../assets/headToToe3.png";
import headToToe4 from "../../../assets/headToToe4.png";
import wipes1 from "../../../assets/wipes1.png";
import wipes2 from "../../../assets/wipes2.png";
import wipes3 from "../../../assets/wipes3.png";
import wipes4 from "../../../assets/wipes4.png";
import wipes5 from "../../../assets/wipes5.png";
import wipes6 from "../../../assets/wipes6.png";
import wipes7 from "../../../assets/wipes7.png";
import activKidz1 from "../../../assets/activKidz1.png";
import activKidz2 from "../../../assets/activKidz2.png";
import activKidz3 from "../../../assets/activKidz3.png";
import activKidz4 from "../../../assets/activKidz4.png";
import activKidz5 from "../../../assets/activKidz5.png";
import activKidz6 from "../../../assets/activKidz6.png";
import Footer from "../../../component/dashboard/Footer";
import newBannerEn from "../../../assets/uploadBabyCarePointChartEN.jpg";
import newBannerBm from "../../../assets/uploadBabyCarePointChartBM.jpg";

const ProductTable = () => {
  const { t } = useTranslation();
  const dBabyBathList = [
    {
      img: dBabyBath,
      name: "Drypers Baby Bath",
      size: "750ml",
      points: "90",
    },
  ];
  const babyShampooList = [
    {
      img: babyShampoo,
      name: "Drypers Baby Shampoo",
      size: "750ml",
      points: "90",
    },
  ];

  const babyPowderList = [
    {
      img: babyPowder,
      name: "Drypers Baby Talcum Oat",
      size: "500g x 2",
      points: "90",
    },
  ];

  const softLotionTissueList = [
    {
      img: softLotionTissue,
      name: "Lotion Tissue",
      size: "40pcs x 5",
      points: "60",
    },
  ];

  const headToToeList = [
    {
      img: headToToe1,
      name: "Drypers Baby Head To Toe Oat/Fruit/Avo/Alm/Shea",
      size: "450ml",
      points: "50",
    },
    {
      img: headToToe2,
      name: "Drypers Baby Head To Toe Oat/Fruit/Avo",
      size: "450ml x 2",
      points: "100",
    },
    {
      img: headToToe3,
      name: "Drypers Baby Head To Toe Oat/Fruit/Avo/Alm/Shea",
      size: "650ml",
      points: "90",
    },
    {
      img: headToToe4,
      name: "Drypers Baby Head To Toe Oat/Fruit/Avo",
      size: "220ml",
      points: "40",
    },
  ];

  const wipesList = [
    {
      img: wipes1,
      name: "Drypers Baby Wipes Oat",
      size: "60s",
      points: "40",
    },
    {
      img: wipes2,
      name: "Drypers Baby Wipes Oat F.F",
      size: "60s",
      points: "40",
    },
    {
      img: wipes3,
      name: "Drypers Baby Wipes H&M",
      size: "120s",
      points: "90",
    },
    {
      img: wipes4,
      name: "Drypers Baby Wipes Oat F.F",
      size: "160s",
      points: "90",
    },
    {
      img: wipes5,
      name: "Drypers Baby Wipes Oat",
      size: "160s",
      points: "90",
    },
    {
      img: wipes6,
      name: "Drypers Baby Wipes Oat F.F",
      size: "30s",
      points: "30",
    },
    {
      img: wipes7,
      name: "Drypers Baby Wipes Oat",
      size: "30s",
      points: "30",
    },
  ];

  const activKidzList = [
    {
      img: activKidz1,
      name: "ActivKidz Drypers Fruity Fresh/Super Berries",
      size: "200ml",
      points: "60",
    },
    {
      img: activKidz2,
      name: "ActivKidz Drypers Fruity Fresh/Super Berries",
      size: "400ml",
      points: "100",
    },
    {
      img: activKidz3,
      name: "ActivKidz Drypers Fruity Fresh/Super Berries",
      size: "550ml",
      points: "140",
    },
    {
      img: activKidz4,
      name: "ActivKidz Drypers Detangling / AntiOdour Shampoo",
      size: "200ml",
      points: "60",
    },
    {
      img: activKidz5,
      name: "ActivKidz Drypers Detangling / AntiOdour Shampoo",
      size: "550ml",
      points: "140",
    },
    {
      img: activKidz6,
      name: "ActivKidz Drypers Handwash",
      size: "200ml",
      points: "60",
    },
  ];
  return (
    <div id="upload-receipt-page">
      <Header />
      <div className="content">
        <ContentHeader
          newImageEn={newBannerEn}
          newImageBm={newBannerBm}
          title={t("pointChartTitle")}
        />
        <div className="mt-140">
          <h3 className="addToCartTitle point-charts-title mb-2">
            {t("pointChartTitle")}
          </h3>
          <div className="table-titles">
            <div className="table-big-title">
              <h3>Item</h3>
            </div>
            <div className="table-small-title">
              <h3>{t("size")}</h3>
            </div>
            <div className="table-small-title">
              <h3>{t("pointsNoSpacing")}</h3>
            </div>
          </div>
          <DropdownComponent
            title={"Baby Bath"}
            dropdownInput={dBabyBathList}
          ></DropdownComponent>
          <DropdownComponent
            title={"Baby Shampoo"}
            dropdownInput={babyShampooList}
          ></DropdownComponent>
          <DropdownComponent
            title={"Baby Powder"}
            dropdownInput={babyPowderList}
          ></DropdownComponent>
          <DropdownComponent
            title={"Soft Lotion Tissue"}
            dropdownInput={softLotionTissueList}
          ></DropdownComponent>
          <DropdownComponent
            title={"Head to Toe"}
            dropdownInput={headToToeList}
          ></DropdownComponent>
          <DropdownComponent
            title={"Wipes"}
            dropdownInput={wipesList}
          ></DropdownComponent>
          <DropdownComponent
            title={"ActivKidz"}
            dropdownInput={activKidzList}
          ></DropdownComponent>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ProductTable;
