import { useState } from "react";
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
import ContentHeader from "../../component/dashboard/ContentHeader";
import newBannerEn from "../../assets/Drypers-baby-Baby-Care_Mata-Ganjaran_Diaper-ENG.jpg";
import newBannerBm from "../../assets/Drypers-baby-Baby-Care_Mata-Ganjaran_Diaper-BM.jpg";
import skinature1 from "../../assets/skinature1.png";
import skinature2 from "../../assets/skinature2.png";
import skinature3 from "../../assets/skinature3.png";
import skinature4 from "../../assets/skinature4.png";
import skinature5 from "../../assets/skinature5.png";
import skinature6 from "../../assets/skinature6.png";
import skinature7 from "../../assets/skinature7.png";
import skinature8 from "../../assets/skinature8.png";
import skinature9 from "../../assets/skinature9.png";
import dTouch1 from "../../assets/dTouch1.png";
import dTouch2 from "../../assets/dTouch2.png";
import dTouch3 from "../../assets/dTouch3.png";
import dTouch4 from "../../assets/dTouch4.png";
import dTouch5 from "../../assets/dTouch5.png";
import dTouch6 from "../../assets/dTouch6.png";
import dTouch7 from "../../assets/dTouch7.png";
import dTouch8 from "../../assets/dTouch8.png";
import dTouch9 from "../../assets/dTouch9.png";
import dTouch10 from "../../assets/dTouch10.png";
import dTouch11 from "../../assets/dTouch11.png";
import dTouch12 from "../../assets/dTouch12.png";
import dTouch13 from "../../assets/dTouch13.png";
import dTouch14 from "../../assets/dTouch14.png";
import dWW1 from "../../assets/dWW1.png";
import dWW2 from "../../assets/dWW2.png";
import dWW3 from "../../assets/dWW3.png";
import dWW4 from "../../assets/dWW4.png";
import dWW5 from "../../assets/dWW5.png";
import dWW6 from "../../assets/dWW6.png";
import dWW7 from "../../assets/dWW7.png";
import dWW8 from "../../assets/dWW8.png";
import dWW9 from "../../assets/dWW9.png";
import dWW10 from "../../assets/dWW10.png";
import dWW11 from "../../assets/dWW11.png";
import dWW12 from "../../assets/dWW12.png";
import drypantz1 from "../../assets/drypantz1.png";
import drypantz2 from "../../assets/drypantz2.png";
import drypantz3 from "../../assets/drypantz3.png";
import drypantz4 from "../../assets/drypantz4.png";
import drypantz5 from "../../assets/drypantz5.png";
import drypantz6 from "../../assets/drypantz6.png";
import dClassic1 from "../../assets/dClassic1.png";
import dClassic2 from "../../assets/dClassic2.png";
import dClassic3 from "../../assets/dClassic3.png";
import dClassic4 from "../../assets/dClassic4.png";
import dClassic5 from "../../assets/dClassic5.png";
import dClassic6 from "../../assets/dClassic6.png";
import dClassicPantz1 from "../../assets/dClassicPantz1.png";
import dClassicPantz2 from "../../assets/dClassicPantz2.png";
import dClassicPantz3 from "../../assets/dClassicPantz3.png";
import dClassicPantz4 from "../../assets/dClassicPantz4.png";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../../component/dropdown/DropdownComponent";

const ProductsInfo = () => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState("skinature");

  const buttonStyle = (val) => {
    if (val === selected) return { backgroundColor: "#fea30b", color: "white" };
    return {};
  };

  const skinatureDrypers = [
    {
      img: skinature1,
      name: "Skinature by Drypers NB",
      size: "66s",
      points: "220",
    },
    {
      img: skinature2,
      name: "Skinature by Drypers S",
      size: "58s",
      points: "220",
    },
    {
      img: skinature3,
      name: "Skinature by Drypers M",
      size: "44s",
      points: "220",
    },
    {
      img: skinature4,
      name: "Skinature by Drypers L",
      size: "38s",
      points: "220",
    },
    {
      img: skinature5,
      name: "Skinature by Drypers XL",
      size: "32s",
      points: "220",
    },
    {
      img: skinature6,
      name: "Skinature Pants by Drypers M",
      size: "38s",
      points: "220",
    },
    {
      img: skinature7,
      name: "Skinature Pants by Drypers L",
      size: "32s",
      points: "220",
    },
    {
      img: skinature8,
      name: "Skinature Pants by Drypers XL",
      size: "26s",
      points: "220",
    },
    {
      img: skinature9,
      name: "Skinature Pants by Drypers XXL",
      size: "22s",
      points: "220",
    },
  ];

  const drypersTouch = [
    {
      img: dTouch1,
      name: "Drypers Touch S",
      size: "44s",
      points: "120",
    },
    {
      img: dTouch2,
      name: "Drypers Touch S",
      size: "70s",
      points: "190",
    },
    {
      img: dTouch3,
      name: "Drypers Touch M",
      size: "40s",
      points: "120",
    },
    {
      img: dTouch4,
      name: "Drypers Touch M",
      size: "64s",
      points: "190",
    },
    {
      img: dTouch5,
      name: "Drypers Touch L",
      size: "34s",
      points: "120",
    },
    {
      img: dTouch6,
      name: "Drypers Touch L",
      size: "54s",
      points: "190",
    },
    {
      img: dTouch7,
      name: "Drypers Touch XL",
      size: "30s",
      points: "120",
    },
    {
      img: dTouch8,
      name: "Drypers Touch XL",
      size: "46s",
      points: "190",
    },
    {
      img: dTouch9,
      name: "Drypers Touch XXL",
      size: "24s",
      points: "120",
    },
    {
      img: dTouch10,
      name: "Drypers Touch XXL",
      size: "36s",
      points: "190",
    },
    {
      img: dTouch11,
      name: "Drypers Touch Pants M",
      size: "44s",
      points: "190",
    },
    {
      img: dTouch12,
      name: "Drypers Touch Pants L",
      size: "38s",
      points: "190",
    },
    {
      img: dTouch13,
      name: "Drypers Touch Pants XL",
      size: "34s",
      points: "190",
    },
    {
      img: dTouch14,
      name: "Drypers Touch Pants XXL",
      size: "30s",
      points: "190",
    },
  ];

  const drypersWeeWee = [
    {
      img: dWW1,
      name: "Drypers Wee Wee Dry NB",
      size: "60s",
      points: "120",
    },
    {
      img: dWW2,
      name: "Drypers Wee Wee Dry NB",
      size: "80s",
      points: "170",
    },
    {
      img: dWW3,
      name: "Drypers Wee Wee Dry S",
      size: "58s",
      points: "120",
    },
    {
      img: dWW4,
      name: "Drypers Wee Wee Dry S",
      size: "80s",
      points: "170",
    },
    {
      img: dWW5,
      name: "Drypers Wee Wee Dry M",
      size: "52s",
      points: "120",
    },
    {
      img: dWW6,
      name: "Drypers Wee Wee Dry M",
      size: "60s",
      points: "170",
    },
    {
      img: dWW7,
      name: "Drypers Wee Wee Dry L",
      size: "44s",
      points: "120",
    },
    {
      img: dWW8,
      name: "Drypers Wee Wee Dry L",
      size: "58s",
      points: "170",
    },
    {
      img: dWW9,
      name: "Drypers Wee Wee Dry XL",
      size: "36s",
      points: "120",
    },
    {
      img: dWW10,
      name: "Drypers Wee Wee Dry XL",
      size: "50s",
      points: "170",
    },
    {
      img: dWW11,
      name: "Drypers Wee Wee Dry XXL",
      size: "32s",
      points: "120",
    },
    {
      img: dWW12,
      name: "Drypers Wee Wee Dry XXL",
      size: "40s",
      points: "170",
    },
  ];

  const drypersDrypantz = [
    {
      img: drypantz1,
      name: "Drypers DryPantz M",
      size: "58s",
      points: "150",
    },
    {
      img: drypantz2,
      name: "Drypers DryPantz L",
      size: "48s",
      points: "150",
    },
    {
      img: drypantz3,
      name: "Drypers DryPantz XL",
      size: "42s",
      points: "150",
    },
    {
      img: drypantz4,
      name: "Drypers DryPantz XXL",
      size: "36s",
      points: "150",
    },
    {
      img: drypantz5,
      name: "Drypers DryPantz XXXL",
      size: "20s",
      points: "150",
    },
    {
      img: drypantz6,
      name: "Drypers DryPantz XXXXL",
      size: "18s",
      points: "150",
    },
  ];

  const drypersClassicOpen = [
    {
      img: dClassic1,
      name: "Drypers Classic NB",
      size: "78s",
      points: "120",
    },
    {
      img: dClassic2,
      name: "Drypers Classic S",
      size: "70s",
      points: "120",
    },
    {
      img: dClassic3,
      name: "Drypers Classic M",
      size: "54s",
      points: "120",
    },
    {
      img: dClassic4,
      name: "Drypers Classic L",
      size: "50s",
      points: "120",
    },
    {
      img: dClassic5,
      name: "Drypers Classic XL",
      size: "44s",
      points: "120",
    },
    {
      img: dClassic6,
      name: "Drypers Classic XXL",
      size: "40s",
      points: "120",
    },
  ];

  const drypersClassicPantz = [
    {
      img: dClassicPantz1,
      name: "Drypers ClassicPantz M",
      size: "58s",
      points: "120",
    },
    {
      img: dClassicPantz2,
      name: "Drypers ClassicPantz L",
      size: "48s",
      points: "120",
    },
    {
      img: dClassicPantz3,
      name: "Drypers ClassicPantz XL",
      size: "44s",
      points: "120",
    },
    {
      img: dClassicPantz4,
      name: "Drypers ClassicPantz XXL",
      size: "36s",
      points: "120",
    },
  ];

  return (
    <div id="products-info-page">
      <Header />
      <div className="content">
        <ContentHeader newImageEn={newBannerEn} newImageBm={newBannerBm} />
        <div className="mt-140">
          <h3 className="addToCartTitle point-charts-title2 mb-2">
            {t("productInfoTitle")}
          </h3>
          <div className="table-titles">
            <div className="table-big-title">
              <h3>Item</h3>
            </div>
            <div className="table-small-title">
              <h3>{t("size")}</h3>
            </div>
            <div className="table-small-title">
              <h3>{t("pointsNoSpacing")}</h3>
            </div>
          </div>
          <DropdownComponent
            title={"Skinature by Drypers"}
            dropdownInput={skinatureDrypers}
          ></DropdownComponent>
          <DropdownComponent
            title={"Drypers Touch"}
            dropdownInput={drypersTouch}
          ></DropdownComponent>
          <DropdownComponent
            title={"Drypers Wee Wee Dry"}
            dropdownInput={drypersWeeWee}
          ></DropdownComponent>
          <DropdownComponent
            title={"Drypers DryPantz"}
            dropdownInput={drypersDrypantz}
          ></DropdownComponent>
          <DropdownComponent
            title={"Drypers Classic Open"}
            dropdownInput={drypersClassicOpen}
          ></DropdownComponent>
          <DropdownComponent
            title={"Drypers ClassicPantz"}
            dropdownInput={drypersClassicPantz}
          ></DropdownComponent>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsInfo;
